<svelte:options tag="carousel-gallery"/>

<script>
    import {draggable} from '@neodrag/svelte';

    let currentPage = 0;
    let width = 0;
    let translate = {x: 0, y: 0};
    let windowWidth = window.innerWidth;
    let lastXTranslation = 0;

    const delta = 6;
    let startDragPosition = {
        x: 0,
        y: 0,
    };

    export let slides = articles; // Get slides from articles_grid.html (global variable)

    $: slidesPerPage = calculateSlidesToShow(windowWidth);
    $: pageCount = calculateNumSlides(windowWidth);

    function calculateNumSlides() {
        return slides.length < slidesPerPage ? 1 : Math.ceil(slides.length / slidesPerPage);
    }

    function calculateSlidesToShow(windowWidth) {
        if (windowWidth < 768) {
            return 1;
        } else if (windowWidth < 991) {
            return 2;
        }

        return 3;
    }

    function translateToCurrent() {
        translate.x = -(((width / slidesPerPage) * slidesPerPage) * currentPage);
    }

    function prev(e) {
        if (currentPage === 0) {
            currentPage = pageCount - 1;
        } else {
            currentPage -= 1;
        }

        translateToCurrent();
    }

    function next(e) {
        if (currentPage === pageCount - 1) {
            currentPage = 0;
        } else {
            currentPage += 1;
        }

        translateToCurrent();
    }

    function thresholdDistance() {
        if (slidesPerPage < 3) {
            return (width / slidesPerPage) * 0.5; // half width of a slide
        } else {
            return (width / slidesPerPage) * 0.66; // 2/3rd width of a slide
        }
    }

    function snap(e) {
        let movedX = lastXTranslation - e.detail.offsetX;

        // if not translated 1 card width to the left or right, snap back
        let movedXAbsolute = Math.abs(movedX);
        if (movedXAbsolute < thresholdDistance()) {
            translateToCurrent();
            return;
        }

        if (movedX < 0) {
            prev();
        } else {
            next();
        }
    }

    function handleShortcut(e) {
        if (e.keyCode === 37) { // 37 = arrow left
            prev();
        }

        if (e.keyCode === 39) { // 39 = arrow right
            next();
        }
    }

    function handleClick(e) {
        const diffX = Math.abs(e.pageX - startDragPosition.x);
        const diffY = Math.abs(e.pageY - startDragPosition.y);

        if (diffX > delta || diffY > delta) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }
</script>

<svelte:window on:keyup={handleShortcut} bind:innerWidth={windowWidth}/>

<div class="carousel-container">
    <div class="content-container">
        <div class="arrow-container">
            <button class="arrow-circle" on:click={prev}>
                <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path
                        d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg>
                </span>
            </button>
        </div>

        <div class="pages-window">
            <div
                class="pages-container"
                bind:clientWidth={width}
                use:draggable={{ position: translate, axis: 'x'}}
                on:neodrag:end={(event) => snap(event)}
                on:neodrag:start={(event) => {
                    lastXTranslation = event.detail.offsetX;
                }}
            >
                {#each slides as slide, id}
                    <div class="color-container" style="max-width: {width/slidesPerPage}px; padding: 10px">
                        <div class="row slide">
                            <div class="class-article slick-card">
                                <div class="row">
                                    {#if slide.image_jpg}
                                        <div class="col l-12 m-12 s-12">
                                            <div class="attribute-image">
                                                <a href="{slide.url}" hreflgang="en" tabindex="-1" draggable="false"
                                                   on:mousedown={(e) => {
                                                       startDragPosition.x = e.pageX;
                                                       startDragPosition.y = e.pageY;
                                                   }}
                                                   on:click={handleClick}
                                                   on:mouseup={handleClick}
                                                >
                                                    <picture>
                                                        <source srcset="{slide.image_webp}"
                                                                type="image/webp" alt="">
                                                        <img src="{slide.image_jpg}" alt="{slide.image_alt}" loading="lazy">
                                                    </picture>
                                                </a>
                                            </div>
                                        </div>
                                    {/if}

                                    <div class="card-content col l-12 m-12 s-12">
                                        <div class="attribute-title">
                                            <h3><a href="{slide.url}" hreflang="en" tabindex="-1">{slide.title}</a>
                                            </h3>
                                        </div>

                                        <div class="attribute-short">
                                            {@html slide.intro}
                                        </div>

                                        <div class="attribute-link">
                                            <p><a href="{slide.url}" hreflang="en">Read more</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                {/each}
            </div>
        </div>

        <div class="arrow-container">
            <button class="arrow-circle" on:click={next}>
                <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path
                        d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg>
                </span>
            </button>
        </div>
    </div>
</div>

<style>
    @import '/static/website/base.css';

    .row.slide {
        margin-top: 0;
        margin-bottom: 0;
        clear: none;
    }

    .slide {
        width: 100%;
        height: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
        flex: 1 0 auto;
        align-items: start;
        justify-content: center;
        display: flex;
    }

    .row.slide::after, .row.slide::before {
        display: none;
    }

    .col.card-content {
        padding: 0 30px 20px;
    }

    .slick-card {
        background-color: #fff;
        transition-property: background-color;
        transition-duration: 200ms;
        transition-timing-function: ease-in-out;
        display: flex;
        height: 100%;
    }

    .slick-card:hover {
        cursor: pointer;
    }

    .slick-card:hover, .slick-card:focus {
        background-color: #f6f6f6;
    }

    .arrow-circle .icon {
        width: 20px;
    }

    .carousel-container {
        width: calc(100% + 100px);
        margin-left: -50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content-container {
        width: 100%;
        display: flex;
        position: relative;
    }

    .pages-window {
        flex: 1;
        display: flex;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
    }

    .pages-container {
        width: 100%;
        display: flex;
    }

    .pages-container:not(.neodrag-dragging) {
        transition-property: transform;
        transition-duration: 500ms;
        transition-timing-function: ease-in-out;
    }

    .color-container {
        width: 100%;
        max-width: 33.33%;
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        justify-content: center;
        user-select: none;
    }

    .arrow-container {
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .arrow-circle {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        font-size: 20px;
        transition: opacity 100ms ease;
        cursor: pointer;
        background: transparent;
    }

    .arrow-circle:hover {
        background-color: transparent;
    }

</style>
